/*--
/*  2.4 - Quick View CSS
/*----------------------------------------*/

// Modal 
.modal{
    &.fade {
        display: block !important;
        opacity: 0;
        visibility: hidden;

        &.show {
            display: block !important;
            opacity: 1;
            visibility: visible;
        }
    }
    & .modal-dialog{
        width: calc(100% - 30px);
        max-width: 64rem;

        @media #{$tablet-device}{
            max-width: 45rem;
        }
        @media #{$large-mobile}{
            max-width: 30rem;
        }
        
        & .modal-content{
            position: relative;
            border: 0;
            & .btn-close{
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 7;
                &:focus {
                    box-shadow: none;
                }
            }
            & .modal-body{
                padding: 30px;
                padding-bottom: 60px;    
                
                @media #{$small-mobile}{
                    padding: 20px;
                    padding-bottom: 50px; 
                }
            }
        }
    }
}

// Quick View Images 
.quick-view-image{
    position: relative;
    margin-top: 30px;

    & .quick-view-top{
        position: relative;

        & img{}            
    }
    & .quick-view-thumbs{
        position: relative;

        & .swiper-slide{            
            cursor: pointer;

            & img{
                border: 1px solid transparent;
                transition: all 0.3s linear;
            }

            &:hover,
            &.swiper-slide-thumb-active{
                & img{
                    border-color: $primary;
                }
            }
        }

        & .swiper-button-next,
        & .swiper-button-prev{
            opacity: 1;
            height: auto;
            width: auto;
            transform: translateY(-50%);
            margin-top: 0;
            outline: none;

            &::after{
                font-size: 20px;
                color: #777777;
                transition: all 0.3s linear;
            }

            &:hover{
                &::after{
                    color: $primary;
                }
            }
        }

        & .swiper-button-next{
            right: -25px;
        }
        & .swiper-button-prev{
            left: -25px;
        }
    }

    & .product-thumbnail{
        max-width: 86%;
        margin: 0 auto;

        @media #{$large-mobile}{
            max-width: 85%;
        }
        @media #{$small-mobile}{
            max-width: 86%;
        }

        & .swiper-container{
            padding-right: 2px;
        }
    }
    & .product-flag{
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;

        & span{
            display: inline-block;
            color: $white;
            padding: 0 5px;
            text-align: center;
            line-height: 20px;
            height: 20px;
            font-size: 12px;
            font-weight: 700;
            border-radius: 5px;
            text-transform: capitalize;
            text-align: center;
            
            &.new{
                background-color: $primary;
            }
            &.discount{
                background-color: $red;
            }
        }
    }
}

// Quick View Content 
.quick-view-content{
    margin-top: 25px;

    & .product-name{
        color: $dark;
        font-size: 24px;
        line-height: 1.3;
        text-transform: capitalize;
        font-weight: 700;
        margin-bottom: 20px;  
        
        @media #{$desktop-device}{
            font-size: 20px;
        }
        @media #{$small-mobile}{
            font-size: 20px;
        }
    }
    & .reference{
        margin-bottom: 10px;
        font-weight: 700;
        color: #253237;

        & span{
            font-weight: 400;
        }
    }
    & .shop-rating-content{
        padding: 0;
        margin: 0;
        list-style: none;

        & li{
            margin: 2px 8px 10px 0;
            display: inline-block;
            
            & .review-star{
                position: relative;
                display: inline-block;
    
                &::before{
                    content: "\f2fc" "\f2fc" "\f2fc" "\f2fc" "\f2fc";
                    font-family: Ionicons;
                    display: inline-block;
                    color: #dbdbdb;
                    font-weight: 400;
                    line-height: 1;
                    vertical-align: middle;
                    font-size: 18px;               
                }
                & .star{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    z-index: 2;
                    overflow: hidden;
    
                    &::before{
                        content: "\f2fc" "\f2fc" "\f2fc" "\f2fc" "\f2fc";
                        font-family: Ionicons;
                        display: inline-block;
                        color: #ffc600;
                        font-weight: 400;
                        line-height: 1;
                        vertical-align: middle;
                        font-size: 18px;
                    }
                }
            } 
            & a{
                color: #253237;
                position: relative;
                line-height: 21px;
                padding: 0;
                font-size: 14px;
                cursor: pointer;
                display: inline-block;
                transition: all 0.3s linear;

                @media #{$small-mobile}{
                    font-size: 13px;
                }

                &:hover{
                    color: $primary;
                }
            }
        }
    }
    & .product-prices{
        margin-top: 15px;

        & .old-price{
            font-weight: 400;
            line-height: 20px;
            color: #9b9b9b;
            font-size: 22px;
            color: #b1b1b1;
            margin-right: 13px;
            text-decoration: line-through;
            display: inline-block;

            @media #{$small-mobile}{
                font-size: 18px;
            }
        }
        & .sale-price{
            color: $primary;
            font-size: 30px;
            font-weight: 600;
            line-height: 30px;
            display: inline-block;
            margin-right: 13px;

            @media #{$small-mobile}{
                font-size: 24px;
            }
        }
        & .discount-percentage{
            font-weight: 400;
            font-size: 14px;
            line-height: 25px;
            color: white;
            padding: 0 10px 0 10px;
            background: #253237;
            vertical-align: 5px;
            text-transform: capitalize;        
            display: inline-block;

            @media #{$small-mobile}{
                font-size: 13px;
                padding: 0 8px 0 8px;
            }
        }
    }
    & .product-description{
        padding-top: 35px;
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 0px;
        padding-bottom: 35px;
        
        & ul{
            padding: 0;
            margin: 0;
            list-style: none;

            & li{
                color: #5a5a5a;
                font-size: 14px;
                font-weight: normal;
                line-height: 24px;
            }
        }
    }
    & .label{
        font-size: 14px;
        font-weight: 700;
        color: #253237;
        display: block;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    & .product-quantity-cart{
        padding-top: 25px;
    }  
    & .product-quantity{
        position: relative;
        padding-right: 24px;
        margin-right: 30px;
        margin-top: 10px;

        @media #{$small-mobile}{
            margin-right: 15px;
        }

        & button{
            position: absolute;
            background: none;
            top: 0;
            right: 0;
            border: 1px solid #ebebeb;
            padding: 0;
            width: 24px;
            height: 24px;
            font-size: 14px;
            transition: all 0.3s linear;

            &:hover{
                background-color: #f1f1f1;
            }
        }
        & input{
            width: 60px;
            height: 48px;
            border: 1px solid #ebebeb;
            border-right: 0;
            padding: 5px 8px;
            font-size: 14px;
            outline: none;
        }
        & *{
            &:first-child{
                top: auto;
                bottom: 0;
            }
        }
    }
    & .product-cart{
        margin-top: 10px;

        & .btn{
            @media #{$small-mobile}{
                padding: 0 20px;
            }
        }
    }
    & .product-additional-info{
        padding-top: 10px;      

        & .panel-product-actions{
            display: inline-block;
            margin-bottom: 0;
            margin-top: 10px;

            & .action-btn{
                line-height: 30px;
                padding: 0;
                border: none;
                color: #626262;
                font-size: 14px;
                background: transparent;
                margin-right: 15px;
                cursor: pointer;
                transition: all 0.3s linear;

                &:hover{
                    color: $primary;
                }
            }
        }        
    }
    & .product-sharing{
        display: flex;
        align-items: center;
        padding-top: 10px;

        & .social-sharing{
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;

            & li{
                margin-bottom: 5px;
                margin-left: 20px;

                @media #{$desktop-device}{
                    margin-left: 15px;
                }

                & a{
                    line-height: 30px;
                    text-align: center;
                    color: #b4b4b4;
                    white-space: normal;
                    text-indent: 0;
                    font-size: 18px;
                    display: inline-block;
                    transition: all 0.3s linear;

                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
    }
}

