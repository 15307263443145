/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/


// Footer Section 
.footer-section{
    background-color: $dark;
}

// Footer Copyright 
.footer-copyright{
    padding-bottom: 40px;
}

// Footer Social 
.footer-social{
    display: flex;
    margin-bottom: 20px;

    @media #{$tablet-device, $large-mobile}{
        justify-content: center;
    }

    & a{
        & + a{
            margin-left: 10px;
        }
        position: relative;
        display: inline-block;
        vertical-align: middle;
        color: $white;
        background: #343434;
        padding: 0;
        font-size: 20px;
        line-height: 40px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        text-align: center;
        border: none;
        z-index: 1;
        transition: all 300ms linear;

        &:hover{
            color: $primary;
        }
    }
}

// Copyright Payment
.copyright-payment{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    @media #{$tablet-device}{
        justify-content: space-between;
    }

    @media #{$large-mobile}{
        display: block;
        text-align: center;
    }

    & .copyright{
        margin-bottom: 20px;

        & p{
            color: $white;
            font-size: 12px;
            margin-right: 60px;
            display: inline-block;

            @media #{$tablet-device,  $large-mobile}{
                margin-right: 0;
            }

            & a{
                color: $primary;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    & .payment{
        margin-bottom: 20px;
    }
}

// Copyright Payment
.back-to-top {
	background: $primary;
	color:  $white;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 100%;
	display: none;
	text-align: center;
	text-decoration: none;
	transition: all 500ms ease-in-out;
	position: fixed;
	right: 30px;
	bottom: 30px;
	font-size: 20px;
    z-index: 28;
    
    &:hover{
        color: $white;
        background: $dark;
    }
}
