/*--
/*  4.6 - Discount Countdown CSS
/*----------------------------------------*/

// Discount Countdown
.discount-countdown{
    padding-top: 140px;
    padding-bottom: 140px;

    @media #{$tablet-device}{
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media #{$large-mobile}{
        padding-top: 70px;
        padding-bottom: 70px;
    }
    @media #{$small-mobile}{
        background-position: center right;
    }
}

// Discount Countdown Content
.discount-countdown-content{
    & .sub-title{
        font-size: 44px;
        font-family: $font-family-code;
        color: $white;
        display: inline-block;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 5px;

        @media #{$desktop-device}{
            font-size: 40px;
        }
        @media #{$tablet-device}{
            font-size: 32px;
        }
        @media #{$large-mobile}{
            font-size: 28px;
        }
    }
    & .title{
        color: $white;
        font-size: 86px;
        font-weight: 700;
        line-height: 1.32;
        margin-bottom: 28px;
        text-transform: uppercase;

        @media #{$laptop-device}{
            font-size: 70px;
        }
        @media #{$desktop-device}{
            font-size: 60px;
        }
        @media #{$tablet-device}{
            font-size: 44px;
            margin-bottom: 18px;
        }
        @media #{$large-mobile}{
            font-size: 32px;
            margin-bottom: 15px;
        }
    }
    & p{
        color: $white;
        font-size: 20px;
        font-weight: 400;
        max-width: 460px;  
        margin-bottom: 0;

        @media #{$tablet-device}{
            font-size: 18px;
        }
        @media #{$large-mobile}{
            font-size: 16px;
        }
    }
}

// Countdown Wrapper
.countdown-wrapper{
    padding-top: 30px;

    @media #{$large-mobile}{
        padding-top: 10px;
    }
    & .countdown{
        display: flex;        

        & .single-countdown{
            display: inline-block;
            padding: 9px 5px;
            text-align: center;
            border: 2px solid $white;
            border-radius: 5px;
            min-width: 70px;
            height: 70px;
            margin-top: 20px;
            position: relative;

            @media #{$tablet-device}{
                min-width: 60px;
                height: 60px;
            }
            @media #{$large-mobile}{
                min-width: 60px;
                width: 60px;
                height: 60px;
                padding: 11px 5px;
            }

            & + .single-countdown{
                margin-left: 20px;

                @media #{$large-mobile}{
                    margin-left: 14px;
                }

                &::before{
                    position: absolute;
                    content: ':';
                    font-size: 24px;
                    color: $white;
                    left: -15px;
                    top: 50%;
                    transform: translateY(-50%);

                    @media #{$large-mobile}{
                        left: -12px;
                    }
                }
            }

            & .number{
                display: block;
                color: $white;
                font-size: 24px;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: 3px;

                @media #{$tablet-device}{
                    font-size: 18px;
                }
                @media #{$large-mobile}{
                    font-size: 16px;
                }
            }
            & .period{
                display: block;
                color: $white;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 400;
                margin-bottom: 3px;

                @media #{$large-mobile}{
                    font-size: 10px;
                }
            }
        }
    }
}

// Countdown btn 
.countdown-btn{
    padding-top: 50px;
}


