/*--
/*  4.3 - Category CSS
/*----------------------------------------*/

.single-category{
    text-align: center;
    margin-top: 30px;
    
    & .category-thumb{
        & a{

        }
    }
    & .category-desc{
        & a{
            font-size: 16px;
            font-weight: 600;
            color: $dark;
            display: block;
            text-transform: capitalize;
            margin-top: 30px;            
            &:hover{
                color: $primary;
            }
        }
    }
}




