
/*--
/*  2.2 - Form CSS	
/*----------------------------------------*/

// Single Form 
.single-form{
    margin-top: 30px;

    & .form-control{
        height: 50px;
        border-radius: 5px;
        padding: 10px 15px;
        border-color: #ebebeb;
        font-size: 14px;

        &:focus{
            box-shadow: none;
            border-color: $primary;
        }

        & + .form-control{
            margin-top: 20px;
        }
    }

    & textarea{
        &.form-control{
            height: 120px;
            resize: none;
        }
    }
}

// Custom Radio
.radio{
    & input[type="radio"]{
        display: none;

        & + label{
            padding-left: 20px;
            position: relative;

            & span{
                width: 13px;
                height: 13px;
                border-radius: 50%;
                border: 1px solid $dark;
                display: block;
                position: absolute;
                top: 4px;
                left: 0;

                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: $dark;
                    top: 0;
                    left: 0;
                    transform: scale(0);
                    transition: all 0.3s linear;
                }
            }
        }
        &:checked{
            & + label{
                & span{
                    &::before{
                        transform: scale(0.7);
                    }
                }
            }
        }
    }
}

// Single Select 2 
.single-select2{
    margin-top: 30px;

    & .form-select2{
        & .select2{
            width: 100% !important;            
        }
        & .select2-container--default {
            & .select2-selection--single {
                height: 50px;
                border-color: #ebebeb;
                transition: all 0.3s linear;

                & .select2-selection__rendered {
                    width: 100%;
                    line-height: 48px;
                    border-radius: 5px;
                    padding-left: 15px;
                    padding-right: 28px;
                }
                & .select2-selection__arrow{
                    height: 50px;
                    width: 36px;
                }
            }
            &.select2-container--open{
                & .select2-selection--single {
                    border-color: $primary;
                }
            }
        }
    }
}


.select2-container--default{
    & .select2-results{
        &  > .select2-results__options {
            max-height: 200px;
            overflow-y: auto;
        } 
    }   

    & .select2-search--dropdown{
        & .select2-search__field {
            border-color: #ebebeb;
        }
    }
} 

.select2-dropdown{
    border-color: #ebebeb;
}
