/*--
/*  4.8 - Blog CSS
/*----------------------------------------*/


// Single Blog 
.single-blog{
    margin-top: 30px;
    z-index: 1;

    & .blog-images{
        position: relative;
        height: 100%;

        & a{
            display: block;
            overflow: hidden;

            & img{
                width: 100%;
                transition: all 0.3s linear;
            }
        }        

        &:hover{
            & a{
                & img{
                    transform: scale(1.05);
                }
            }            
        }
    }
    & .gallery-active{
        position: relative;
        height: 100%;

        // Swiper Button Next & Prev
        & .swiper-button-next,
        & .swiper-button-prev{
            color: $white;
            transition: all 0.3s linear;

            &::after{
                font-size: 30px;               
            }

            &:hover{
                color: $dark;
            }
        }
    }
    & .blog-embed{
        height: 100%;

        & .ratio-16x9 {
            --aspect-ratio: calc(9.55 / 15 * 100%);
        }        
    }
    & .meta-category{
        position: absolute;
        left: 10px;
        z-index: 5;
        bottom: 10px;
        
        & a{
            font-size: 12px;
            text-transform: uppercase;
            background: $white;
            color: #253237;
            line-height: 28px;
            border-radius: 30px;
            padding: 0 15px;
            display: inline-block;

            &:hover{
                background-color: $primary;
                color: $white;
            }
        }
    }
    & .blog-content{
        padding-top: 30px;

        & .blog-title{
            margin-bottom: 0;

            & a{
                font-size: 16px;
                line-height: 1.3;
                color: $dark;
                font-weight: 600;
                text-transform: capitalize;
                position: relative;
                display: block;
                margin-bottom: 20px;
                transition: all 0.3s linear;

                &:hover{
                    color: $primary;
                }
            }
        }
        & p{
            font-size: 13px;
            font-weight: 400;
            color: #555;
            line-height: 22px;
            display: inline-block;
            width: 100%;
            margin-bottom: 30px;
        }
        & .post-meta{
            overflow: hidden;
            position: relative;
            border-top: 1px solid #ebebeb;
            border-bottom: 1px solid #ebebeb;
            padding: 15px 0;
            
            & span{
                font-size: 13px;
                margin-right: 5px;
                display: inline-block;

                & span{
                    color: $primary;
                }

                &:last-child{
                    margin-right: 0;
                }
            }
        }
        & .more{
            font-size: 14px;

            &:hover{
                color: $primary;
            }
        }
    }

    &.blog-list{
        display: flex;

        @media #{$large-mobile}{
            display: block;
        }

        & .blog-images,
        & .gallery-active,
        & .blog-embed{
            width: 40%;

            @media #{$desktop-device, $tablet-device}{
                width: 45%;
            }
            @media #{$large-mobile}{
                width: 100%;
            }

            & img{
                @media #{$desktop-device, $tablet-device}{
                    height: 242px;
                    object-fit: cover;
                    object-position: center;
                }
            }

           
        }

        & .blog-content{
            width: 60%;
            text-align: left;
            padding-top: 0;
            padding-left: 30px;
            flex: 1;

            @media #{$desktop-device, $tablet-device}{
                width: 65%;
            }
            @media #{$large-mobile}{
                width: 100%;
                padding-left: 0;
                padding-top: 25px;
            }

            & p{
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
}

// Blog Active 
.blog-active{
    & .swiper-button-next,
    & .swiper-button-prev{
        outline: none;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background-color: $white;
        color: $body-color;
        font-size: 14px;
        border: 1px solid #ebebeb;
        border-radius: 50%;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
        z-index: 9;

        &::after{
            display: none;
        }

        &:hover{
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }
    & .swiper-button-prev {
        left: 0px;
        right: auto;
    }
    & .swiper-button-next {
        right: 0px;
        left: auto;
    }

    &:hover{
        & .swiper-button-next,
        & .swiper-button-prev{
            opacity: 1;
            visibility: visible;
        }
    }
}

// Blog Wrapper
.blog-wrapper{
    padding-top: 20px;
}




