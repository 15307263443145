/*--
/*  2.1 - Button CSS
/*----------------------------------------*/

.btn {
    text-transform: uppercase;
    white-space: nowrap;
}

@each $name, $value in $theme-colors {  
    .btn-#{$name} {
        @if $name == light {
            color: $dark;
        } @else if $name == warning {
            color: $dark;
        } @else if $name == whites {
            color: $dark;
        } @else {
            color: $white;
        }
    } 
    .btn-hover-#{$name} {
        &:hover {
            border-color: $value;
            background-color: $value;
            @if $name == light {
                color: $dark;
            } @else if $name == warning {
                color: $dark;
            } @else if $name == whites {
                color: $dark;
            } @else {
                color: $white;
            }
        }
    }
}

