/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/

// Single Slider 
.single-slider{
    height: 720px;
    display: flex;
    align-items: center;
    background-size: cover;

    @media #{$extraBig-device}{
        height: 930px;
    }
    @media #{$tablet-device}{
        height: 620px;
    }
    @media #{$large-mobile}{
        height: 530px;
    }
}

.single-slider-02{
    height: 535px;
    display: flex;
    align-items: center;
    background-size: cover;    

    @media #{$tablet-device, $large-mobile}{
        background-position: left center;
    }
}

.single-slider-03{
    height: 800px;
    display: flex;
    align-items: center;
     
    @media #{$desktop-device}{
        height: 600px;
    }
    @media #{$tablet-device, $large-mobile}{
        height: 650px;
    }
    @media #{$small-mobile}{
        height: 550px;
    }

    // Slider Active 
    & .slider-active{
        & .swiper-pagination{
            & .swiper-pagination-bullet {
                &.swiper-pagination-bullet-active{
                    background-color: $dark;
                }
            }
        }
    }
}


// Slider Content 
.slider-content{
    padding-top: 80px;

    & .sub-title{
        font-size: 18px;
        color: $dark;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 15px;
        text-transform: uppercase;

        @media #{$large-mobile}{
            font-size: 16px;
        }
    }
    & .main-title{
        font-size: 82px;
        color: $dark;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;

        @media #{$tablet-device}{
            font-size: 70px;
        }
        @media #{$large-mobile}{
            font-size: 48px;
        }
        @media #{$small-mobile}{
            font-size: 38px;
        }
        
        & span{
            font-weight: 400;
        }
    }

    & .btn{
        margin-top: 40px;
    }
}
.slider-content-02{
    max-width: 700px;
    padding: 0 30px;

    @media #{$tablet-device, $large-mobile}{
        padding: 90px 30px 0;
    }

    @media #{$small-mobile}{
        padding: 90px 15px 0;
    }

    & .sub-title{
        font-size: 18px;
        color: $white;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 15px;
        text-transform: uppercase;

        @media #{$large-mobile}{
            font-size: 16px;
        }
    }
    & .main-title{
        font-size: 82px;
        color: $white;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 25px;

        @media #{$desktop-device, $tablet-device}{
            font-size: 70px;
        }
        @media #{$large-mobile}{
            font-size: 48px;
        }
        @media #{$small-mobile}{
            font-size: 34px;
        }
        
        & span{
            font-weight: 400;
        }
    }
    & p{
        color: $white;
        margin-bottom: 0;
    }
    & .btn{
        margin-top: 40px;
    }
}

// Slider Active 
.slider-active{
    & .swiper-pagination{
        bottom: 30px;
        height: 18px;

        & .swiper-pagination-bullet {
            display: inline-block;
            margin: 0 5px;
            cursor: pointer;
            font-size: 0;
            width: 14px;
            height: 14px;
            background: $white;
            border: none;
            border-radius: 100%;
            transition: all 300ms linear;
            opacity: 1;
            outline: none;

            &.swiper-pagination-bullet-active{
                background-color: $primary;
            }
        }
    }

    // Slider 03
    &.slider-03{    
        & .swiper-pagination{
            & .swiper-pagination-bullet {
                &.swiper-pagination-bullet-active{
                    background-color: $dark;
                }
            }
        }        
    }
}


// Single Slider Animation
.animation-style-01{
    & .slider-content,
    & .slider-content-02{
        opacity: 0;
    }
    
    &.swiper-slide-active{
        & .slider-content{
            opacity: 1;

            & .sub-title{
                animation-name: fadeInUp;
                animation-delay: 0.5s;
                animation-duration: 1.3s;
                animation-fill-mode: both;                
            }
            & .main-title{
                animation-name: fadeInUp;
                animation-delay: 1s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & .btn{
                animation-name: fadeInLeft;
                animation-delay: 1.5s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
        }
        & .slider-content-02{
            opacity: 1;

            & .sub-title{
                animation-name: fadeInUp;
                animation-delay: 0.5s;
                animation-duration: 1.3s;
                animation-fill-mode: both;                
            }
            & .main-title{
                animation-name: fadeInUp;
                animation-delay: 1s;
                animation-duration: 1.3s;
                animation-fill-mode: both;
                
            }
            & p{
                animation-name: fadeInUp;
                animation-delay: 1.5s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & .btn{
                animation-name: fadeInLeft;
                animation-delay: 2s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
        }
    }
}



