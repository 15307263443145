/*--
/*  4.5 - Product CSS
/*----------------------------------------*/

// Single Price 
.single-product{
    position: relative;
    background: $white;
    z-index: 1;
    margin-top: 30px;
    margin-bottom: 1px;
    border: 1px solid #ebebeb;
    overflow: hidden;
    border-radius: 10px;
    transition: all 300ms linear;

    & .product-image{
        position: relative;
        overflow: hidden;

        & .product-thumbnail{
            display: block;

            & img{
                width: 100%;
                transition: all 0.6s linear;
    
                &.image-hover{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    transition: all 0.6s linear;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
        & .product-action{
            position: absolute;
            top: 20px;
            right: 20px;

            @media #{$laptop-device, $desktop-device, $tablet-device, $large-mobile }{
                top: 15px;
                right: 15px;
            }
            
            & .action{
                display: block;
                background: $white;
                color: $dark;
                padding: 0;
                box-shadow: 0px 2px 4.85px 0.15px rgba($black, 0.1);
                line-height: 45px;
                height: 45px;
                width: 45px;
                border: 0;
                font-size: 22px;
                text-align: center;
                text-transform: capitalize;
                border-radius: 100%;
                margin-bottom: 6px;
                visibility: hidden;
                opacity: 0;
                transform: translateX(30px);
                perspective: 800px;
                backface-visibility: hidden;

                @media #{$laptop-device, $desktop-device, $tablet-device, $large-mobile }{
                    line-height: 35px;
                    height: 35px;
                    width: 35px;
                    font-size: 18px;
                }

                &:nth-child(1){
                    transition: opacity .3s ease,visibility .3s ease,transform .3s ease,-webkit-transform .3s ease;
                }
                &:nth-child(2){
                    transition: opacity .5s ease,visibility .5s ease,transform .5s ease,-webkit-transform .5s ease;
                }
                &:nth-child(3){
                    transition: opacity .7s ease,visibility .7s ease,transform .7s ease,-webkit-transform .7s ease;
                }
                &:nth-child(4){
                    transition: opacity .9s ease,visibility .9s ease,transform .9s ease,-webkit-transform .9s ease;
                }

                &:hover{
                    background-color: $primary;
                    color: $white;
                }
            }
        }
        & .product-flag{
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;

            & span{
                display: inline-block;
                color: $white;
                padding: 0 5px;
                text-align: center;
                line-height: 20px;
                height: 20px;
                font-size: 12px;
                font-weight: 700;
                border-radius: 5px;
                text-transform: capitalize;
                text-align: center;
                
                &.new{
                    background-color: $primary;
                }
                &.discount{
                    background-color: $red;
                }
            }
        }        
    }

    & .product-content{
        padding: 20px 15px 30px 15px;
        background: $white;
        overflow: hidden;
        text-align: center;
        position: relative;
        transition: all 300ms linear;
        
        & .product-title{
            margin-bottom: 0;
            & a{
                color: $dark;
                font-size: 14px;
                text-transform: capitalize;
                line-height: 1.28;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 11px;
                display: block;
                transition: all 0.3s linear;

                &:hover{
                    color: $primary;
                }
            }
        }
        & .manufacturer{
            & a{
                font-size: 14px;
                text-transform: capitalize;
                color: $body-color;
                line-height: 1;
                margin-bottom: 17px;
                display: block;
                transition: all 0.3s linear;

                &:hover{
                    color: $dark;
                }
            }
        }
        & .product-price{
            & .regular-price{
                color: #9b9b9b;
                font-weight: 400;
                text-decoration: line-through;
                margin-right: 2px;
                font-size: 14px;
                
            }
            & .sele-price{
                color: $primary;
                font-weight: 600;
                margin-right: 2px;
                font-size: 16px;
            }
        }
    }

    &.product-list{
        display: flex;

        @media #{$large-mobile}{
            display: block;
        }

        & .product-image{
            width: 345px;

            @media #{$desktop-device, $tablet-device}{
                width: 290px;
            }
            @media #{$large-mobile}{
                width: 100%;
            }
        }
        & .product-content{
            flex: 1;
            text-align: left;
            padding-left: 15px;

            & .product-title{
                margin-bottom: 0;
                & a{
                    font-size: 18px;

                    @media #{$large-mobile}{
                        font-size: 14px;
                    }
                }
            }
            & .product-price{
                & .regular-price{}
                & .sele-price{
                    font-size: 22px;

                    @media #{$large-mobile}{
                        font-size: 16px;
                    }
                }
            }
            & .product-desc {
                display: block;
                padding: 30px 0;
                margin: 0;
                font-size: 13px;
                color: #626262;
                line-height: 24px;

                @media #{$desktop-device, $tablet-device}{
                    padding: 15px 0;
                }
            }
            & .availability{
                & p{
                    font-size: 14px;
                    text-transform: capitalize;
                    color: #777777;
                    line-height: 1;
                    display: block;
                
                    & span{
                        font-weight: 700;
                        color: $primary;
                    }
                }
            }
            & .cart-btn{
                margin-top: 30px;
                border: 0;
                padding: 0;
            }
        }        
    }

    &:hover{
        border-color: $primary;

        & .product-image{
            & .product-thumbnail{
                & img{
                    &:not(:last-child) {
                        opacity: 0;
                        visibility: hidden;
                    }
                    &.image-hover{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            & .product-action{
                & .action{
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    -webkit-transform: translateX(0);                    
                }
            }
        }
    }
}

// Features Product Item
.features-product-item{
    position: relative;
    background: $white;
    z-index: 1;
    margin-top: 30px;
    margin-bottom: 1px;
    border: 1px solid #ebebeb;
    overflow: hidden;
    border-radius: 10px;
    transition: all 300ms linear;
    display: flex;
    padding: 19px 10px 19px 5px;
    align-items: center;

    @media #{$laptop-device, $desktop-device}{
        padding: 6px 4px;
    }
    @media #{$large-mobile}{
        padding: 6px 4px;
    }

    & .product-image{
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        width: 120px;

        @media #{$laptop-device}{
            width: 100px;
        }
        @media #{$desktop-device}{
            width: 80px;
        }
        @media #{$large-mobile}{
            width: 95px;
        }

        & .product-thumbnail{
            display: block;

            & img{
                width: 100%;
                transition: all 0.6s linear;
    
                &.image-hover{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    transition: all 0.6s linear;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
        & .product-flag{
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;

            & span{
                display: inline-block;
                color: $white;
                padding: 0 5px;
                text-align: center;
                line-height: 20px;
                height: 20px;
                font-size: 12px;
                font-weight: 700;
                border-radius: 5px;
                text-transform: capitalize;
                text-align: center;
                
                &.new{
                    background-color: $primary;
                }
                &.discount{
                    background-color: $red;
                }
            }
        }        
    }

    & .product-content{
        padding-left: 20px;
        overflow: hidden;

        @media #{$laptop-device, $desktop-device, $large-mobile}{
            padding-left: 10px;
        }

        & .product-title{
            margin-bottom: 0;
            & a{
                color: $dark;
                font-size: 14px;
                text-transform: capitalize;
                line-height: 1.28;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 11px;
                display: block;
                transition: all 0.3s linear;

                @media #{$desktop-device}{
                    font-size: 13px;
                    margin-bottom: 8px;
                }

                &:hover{
                    color: $primary;
                }
            }
        }
        & .manufacturer{
            & a{
                font-size: 14px;
                text-transform: capitalize;
                color: $body-color;
                line-height: 1;
                margin-bottom: 17px;
                display: block;
                transition: all 0.3s linear;

                @media #{$desktop-device}{
                    font-size: 13px;
                    margin-bottom: 12px;
                }

                &:hover{
                    color: $dark;
                }
            }
        }
        & .product-price{
            & .regular-price{
                color: #9b9b9b;
                font-weight: 400;
                text-decoration: line-through;
                margin-right: 2px;
                font-size: 14px;
                
                @media #{$desktop-device}{
                    font-size: 13px;
                }
            }
            & .sele-price{
                color: $primary;
                font-weight: 600;
                margin-right: 2px;
                font-size: 16px;

                @media #{$desktop-device}{
                    font-size: 14px;
                }
            }
        }
    }

    &:hover{
        border-color: $primary;
    
        & .product-image{
            & .product-thumbnail{
                & img{
                    &:not(:last-child) {
                        opacity: 0;
                        visibility: hidden;
                    }
                    &.image-hover{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

// Features Product Wrapper
.features-product-wrapper{
    & .title{
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1;
        font-size: 24px;
        color: $dark;
        text-transform: uppercase;
        margin-bottom: 10px;

        @media #{$large-mobile}{
            font-size: 20px;
        }
    }
}

// Product active 
.product-active,
.product-2-active,
.product-3-active,
.product-4-active,
.product-5-active{
    position: relative;

    & .swiper-button-next,
    & .swiper-button-prev{
        outline: none;
        width: 34px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background-color: $white;
        color: $body-color;
        font-size: 14px;
        border: 1px solid #ebebeb;
        border-radius: 50%;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;

        &::after{
            display: none;
        }

        &:hover{
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }
    & .swiper-button-prev {
        left: 0px;
        right: auto;
    }
    & .swiper-button-next {
        right: 0px;
        left: auto;
    }

    &:hover{
        & .swiper-button-next,
        & .swiper-button-prev{
            opacity: 1;
            visibility: visible;
        }
    }
}

// Product BG 
.product-nav{
    padding-top: 20px;

    & li{
        text-transform: uppercase;
        padding: 10px 20px 0;
        cursor: pointer;
        
        & a{
            font-size: 14px;
            color: $dark;
            font-weight: 600;
            font-style: inherit;
            border-bottom: 1px solid transparent;
            transition: all 300ms linear;

            @media #{$small-mobile}{
                font-size: 13px;
            }

            &:hover,
            &.active{
                border-color: $primary;
                color: $primary;
            }
        }
    }
}

// Product Tab Content
.product-tab-content{
    padding-top: 20px;
}

// Product BG 
.product-bg{
    background-color: #fafafa;
}

// Hot Product 
.hot-product{}

// Product Left Sidebar
.product-left-sidebar{}

.shop-by-category{
    & .title{
        color: $white;
        font-size: 18px;
        text-transform: uppercase;
        height: 66px;
        line-height: 66px;
        cursor: pointer;
        margin: 0;
        padding-left: 30px;
        background: $primary;
        border-radius: 10px 10px 0 0;        
        font-weight: 700;

        @media #{$desktop-device}{
            height: 60px;
            line-height: 60px;
            font-size: 15px;
            padding-left: 20px;
        }
    }

    & .category-menu{
        display: block;
        width: 100%;
        background: $white;
        border: 1px solid #e8e8e8;
        top: 100%;
        left: 0;
        border-radius: 0px 0px 5px 5px;
        padding: 5px 0;
        
        & li{
            position: relative;

            & a{
                position: relative;
                display: block;
                overflow: hidden;
                text-transform: capitalize;
                word-wrap: break-word;
                background: $white;
                color: #626262;
                padding: 12px 0;
                margin: 0 30px;
                font-size: 14px;
                font-weight: 400;
                transition: none;
                border-bottom: 1px solid #ebebeb;
                transition: all 0.3s linear;

                @media #{$desktop-device}{
                    margin: 0 20px;
                }
                &:hover{
                    color: $primary;
                }
            }

            &:last-child{
                & a{
                    border-bottom: 0;
                }
            }
        }
    }
}
