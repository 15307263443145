/*--
/*  4.11 - Shop CSS
/*----------------------------------------*/

// Shop Top bar 
.shop-top-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;    
    flex-wrap: wrap;

    & .nav{
        & li{
            & a{
                line-height: 30px;
                height: 30px;
                margin-right: 15px;
                font-size: 18px;
                line-height: 30px;
                cursor: pointer;
                color: $dark;
                background: transparent;
                transition: all 300ms linear;

                &:hover,
                &.active{
                    color: $primary;
                }
            }
        }
    }

    & .top-bar-select{
        display: flex;

        & span{
            line-height: 20px;
            font-size: 14px;
            padding: 5px;
            margin: 0;
            color: $dark;
            font-weight: 400;
            text-transform: capitalize;            
        }
        & .nice-select{
            border: 1px solid #ebebeb;
            padding: 2px 15px;
            line-height: 24px;
            font-size: 14px;
            margin: 0;
            border-radius: 30px;
            color: #253237;
            text-align: left;
            box-shadow: none; 
            height: auto;
            float: none;
            min-width: 400px;
            flex: 1;
            margin-left: 15px;

            @media #{$large-mobile}{
                min-width: 250px;
            }
            @media #{$small-mobile}{
                min-width: 210px;
            }

            &::after{
                width: 7px;
                height: 7px;
            }

            & .current{
                              
            }
            & .list{
                width: 100%;
                border-radius: 0;
                margin-top: 1px;

                & .option{
                    line-height: 24px;
                    font-size: 14px;
                    padding: 3px 15px;
                    color: #253237; 
                    min-height: auto;                  
                }
            }
        }
    }
}







