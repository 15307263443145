/*--
/*  3.5 - Header Media CSS
/*----------------------------------------*/

// Header Media 
.header-media{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 370px;
    z-index: 9;
    background: $white;   

    @media #{$laptop-device}{
        width: 270px;
    }
    @media #{$desktop-device}{
        width: 230px;
    }
    
    &::before {
        content: "";
        background: #ebebeb;
        height: 100%;
        width: 1px;
        position: absolute;
        right: 0;
        top: 89px;
    }

    & .header-logo {
        padding: 25px 0 25px 30px;
        box-shadow: 0px 7px 14.25px 0.75px rgba($black, 0.06);

        @media #{$desktop-device}{
            padding-left: 20px;
        }

        & img{
            
        }
    }
}

// Vertical Menu 
.vertical-menu{
    position: relative;
    z-index: 9;
    margin-top: 40px;
    padding-left: 30px;
    padding-right: 1px;  
    
    @media #{$desktop-device}{
        padding-left: 20px;
    }
    
    & .vertical-title{
        color: #b6b6b6;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 400;
        cursor: pointer;
        margin: 0;
        text-align: left;        
    }
    & .vertical-menu-content{
        display: block;
        width: 100%;
        background: $white;
        text-align: left;
        margin-top: 30px;
        
        & > li{
            display: block;
            position: relative !important;

            & a{
                position: relative;
                display: block;
                overflow: hidden;
                line-height: 40px;
                text-transform: capitalize;
                word-wrap: break-word;
                color: $black;
                font-size: 14px;
                font-weight: 400;

                @media #{$desktop-device}{
                    font-size: 13px;
                }

                & .img-icon {
                    margin-right: 12px;
                    display: inline-block;
                    vertical-align: middle;
                    width: 21px;
                    height: 21px;
                }
            }

            &.menu-item-has-children {
                & > a {
                    &::after {
                        content: "\f105";
                        font-family: 'Line Awesome Free';
                        margin-left: 5px;
                        font-weight: 700;
                        font-size: 12px;
                        display: block;
                        float: right;
                        padding-right: 30px;

                        @media #{$desktop-device}{
                            padding-right: 20px;
                        }
                    }
                }
            }

            &.active,
            &:hover{
                & a{
                    color: $primary;
                }
            }
            &:hover{
                & .sub-menu,
                & .mega-sub-menu{
                    opacity: 1;
                    visibility: visible;
                    transform: rotateX(0deg);
                    -o-transition: -o-transform 0.3s, opacity 0.3s;
                    -ms-transition: -ms-transform 0.3s, opacity 0.3s;
                    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
                    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
                }
            }
        }

        // Sub Menu 
        & .sub-menu{
            position: absolute;
            min-width: 240px;
            left: 100%;
            top: 0;
            background-color: $white;
            border: 1px solid #e8e8e8;
            opacity: 0;
            visibility: hidden;
            -o-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -webkit-transform-origin: 0% 0%;
            transform-style: preserve-3d;
            -o-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            -webkit-transform-style: preserve-3d;
            transform: rotateX(-75deg);
            z-index: 9;
            
            & > li{
                position: relative;
                padding: 0 20px;
                line-height: 40px;
                height: 40px;
                border-bottom: 1px solid #eeeeee;
                display: block;

                &:last-child{
                    border-bottom: 0;
                }
                
                & > a{
                    display: block;
                    color: #888888;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: capitalize;
                    transition: all 0.3s linear;
                }

                & .sub-menu{
                    opacity: 0;
                    visibility: hidden;
                    -o-transform-origin: 0% 0%;
                    -ms-transform-origin: 0% 0%;
                    -moz-transform-origin: 0% 0%;
                    -webkit-transform-origin: 0% 0%;
                    transform-style: preserve-3d;
                    -o-transform-style: preserve-3d;
                    -moz-transform-style: preserve-3d;
                    -webkit-transform-style: preserve-3d;
                    transform: rotateX(-75deg);
                }

                &:hover{
                    & > a{
                        color: $primary;
                        padding-left: 10px;
                    }

                    & .sub-menu{
                        opacity: 1;
                        visibility: visible;
                        transform: rotateX(0deg);
                        -o-transition: -o-transform 0.3s, opacity 0.3s;
                        -ms-transition: -ms-transform 0.3s, opacity 0.3s;
                        -moz-transition: -moz-transform 0.3s, opacity 0.3s;
                        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
                    }
                }
            }
        }

        // Mega Sub Menu 
        & .mega-sub-menu{
            position: absolute;
            top: 0;
            left: 100%;
            right: 0;
            margin-left: -1px;
            width: 900px;
            border: 1px solid #e8e8e8;
            background-color: $white;
            z-index: 9;
            padding: 30px 15px;    
            opacity: 0;
            visibility: hidden;
            transform-origin: 0% 0%;
            transform-style: preserve-3d;
            transform: rotateX(-75deg);
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;

            @media #{$desktop-device}{
                width: 730px;
            }

            & > li{
                width: 33.33%;
                padding: 0 15px;

                &.manu-banner{
                    width: 50%;
                    margin-top: 20px;
                    position: relative;

                    & img{
                        width: 100%;
                    }

                    & a {
                        &::before {
                            content: "";
                            background: rgba($white, 0.3);
                            bottom: 50%;
                            top: 50%;
                            left: 0;
                            right: 0;
                            pointer-events: none;
                            transition: all 900ms linear;
                        }
                        &::after {
                            content: "";
                            background: rgba($white, 0.3);
                            left: 51%;
                            right: 50%;
                            top: 0;
                            bottom: 0;
                            pointer-events: none;
                            transition: all 900ms linear;
                        }

                        &:hover{
                            &::before {
                                bottom: 0;
                                left: 0;
                                position: absolute;
                                right: 0;
                                top: 0;
                                transition: all 900ms linear;
                                opacity: 0;
                            }
                            &::after {
                                bottom: 0;
                                left: 0;
                                position: absolute;
                                right: 0;
                                top: 0;
                                transition: all 900ms linear;
                                opacity: 0;
                            }
                        }
                    }
                }

                & .menu-title{
                    color: $dark;
                    line-height: 1;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 700;
                    display: block;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #ebebeb;                   
                    
                    &::after{
                        display: none;
                    }
                }

                &:hover{
                    & > a{
                        color: $primary;
                    }
                }

                & .menu-item{
                    flex-direction: column;
                    margin-top: 20px;

                    & > li{
                        & > a{
                            color: #69696c;
                            text-transform: capitalize;
                            line-height: 28px;
                            font-weight: 400;
                            font-size: 15px;
                            display: block;                        
                        }

                        &:hover{
                        & > a{
                            padding-left: 10px;
                            color: $primary;
                        }
                        }
                    }
                }
            }
        }
    }
}

// Header Social
.header-social{
    margin: 100px 30px 0 30px;
    padding-top: 35px;
    border-top: 1px solid #ebebeb;

    @media #{$desktop-device}{
        margin-left: 20px;
    }
    
    & .social-title{
        text-align: left;
        color: $dark;
        text-transform: uppercase;
        font-size: 14px;
        margin: 0 0 20px;  
        font-weight: 700;      
    }
    & .social{
        & a{
            position: relative;
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            padding: 0;
            line-height: 40px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            text-align: center;
            border: none;
            z-index: 1;
            transition: all 300ms linear;
            color: #444444;
            background: transparent;
            border: 1px solid #ebebeb;
            margin-right: 10px;

            &:hover{
                background-color: $primary;
                border-color: $primary;
                color: $white;
            }
        }
    }
}



